import { Link } from '@remix-run/react';
import { useParams } from '@remix-run/react';
import copy from 'copy-to-clipboard';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Fragment,
  type ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTitle } from 'react-use';
import useSWR from 'swr';
import { match } from 'ts-pattern';

import {
  type DtoEventIntakeForm,
  type DtoIntakeEvent,
  EnumsIntakeFormStatus,
  type ModelsEventIntakeFormData,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import lpLogo from '../../assets/img/lp-3d-logo.png';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { apiService } from '../../services/api-service';
import { type Event } from '../../services/api-service/event.api';
import { NotificationType } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { err2s, makeTitle, uuidv4 } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { type Action, ActionSheet } from '../ActionSheet';
import { EnterExitTailwindTransition } from '../common/TailwindTransition';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { CopyIcon } from '../icons/CopyIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { EmailIcon2 } from '../icons/EmailIcon';
import { EyeIcon } from '../icons/EyeIcon';
import { InfoIcon } from '../icons/InfoIcon';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';
import { useUser } from '../UserContext';
import { eventFieldString, EventUtils } from './utils';

function LinkIntakeModal(props: {
  event: Event;
  onCancel: () => void;
  onConfirm: (form: DtoEventIntakeForm) => void;
}) {
  const { event } = props;
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const orgRef = event.data?.orgId ?? event.data?.orgName;
  const swr = useSWR(orgRef ? ['/events', orgRef] : null, async () => {
    const p = apiService.event.queryEvents(
      {
        type: 'live',
        orgRef,
      },
      100
    );
    return await p.next();
  });

  const {
    call: linkForm,
    state: { state, error },
  } = useLiveAsyncCall(async () => {
    if (!selectedEvent?.intakeForm) return;
    const resp = await apiService.event.createIntakeForm(
      event.id,
      selectedEvent.intakeForm.id
    );
    return resp.data.form;
  });

  const onLink = async () => {
    const form = await linkForm();
    if (!form) return;
    props.onConfirm(form);
  };

  return (
    <ModalWrapper containerClassName='w-240' borderStyle='gray'>
      <div className='w-full min-h-80 flex flex-col items-center justify-start px-4 py-10 gap-4'>
        <div className='text-xl font-bold'>
          Select what event should we get the intake from?
        </div>
        <div className='w-full max-h-100 overflow-auto scrollbar'>
          {match(swr)
            .when(
              () => swr.isLoading,
              () => <Loading />
            )
            .when(
              () => swr.error,
              () => (
                <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
              )
            )
            .otherwise(() => (
              <table
                className='w-full text-sms'
                style={{
                  borderCollapse: 'separate',
                  borderSpacing: '0 0.5rem',
                }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th className='text-left'>Organizer</th>
                    <th className='text-left'>Game Pack</th>
                    <th className='text-left'>Date</th>
                    <th className='text-left'>Host</th>
                    <th className='text-left'>Studio</th>
                    <th className='text-left'>Venue</th>
                  </tr>
                </thead>
                <tbody>
                  {swr.data
                    ?.filter((e) => !!e.intakeForm)
                    .map((e, i) => (
                      <tr
                        key={e.id}
                        className={`cursor-pointer ${
                          i % 2 === 0 ? 'bg-secondary' : ''
                        }`}
                        onClick={() => setSelectedEvent(e)}
                      >
                        <td>
                          <input
                            name='intakeFormId'
                            type='radio'
                            className='field-radio w-4 h-4'
                            checked={e.id === selectedEvent?.id}
                            onClick={() => setSelectedEvent(e)}
                          />
                        </td>
                        <td>{eventFieldString(e, 'organizer')}</td>
                        <td>{e.gamePack?.name}</td>
                        <td>{EventUtils.NYTime(e.startAt)}</td>
                        <td>{e.host?.name}</td>
                        <td>{e.studio?.name}</td>
                        <td>{e.venue?.name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ))}
        </div>
        <div className='flex items-center mt-auto gap-1'>
          <div className='flex items-center gap-4'>
            <button
              type='button'
              className='w-40 h-10 btn-secondary'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='w-40 h-10 btn-primary flex items-center justify-center gap-2'
              disabled={!selectedEvent || state.isRunning}
              onClick={onLink}
            >
              {state.isRunning && <Loading text='' />}
              <div>Link</div>
            </button>
          </div>
          {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        </div>
      </div>
    </ModalWrapper>
  );
}

function SendIntakeEmailModal(props: {
  event: Event;
  form: DtoEventIntakeForm;
  onCancel: () => void;
  onConfirm: (form: DtoEventIntakeForm) => void;
}) {
  const { event, form } = props;
  const swr = useSWR(
    `/events/${form.eventId}/intake/${form.id}/prev-event`,
    async () => {
      const resp = await apiService.event.getPrevEvent(form.eventId, form.id);
      return resp.data;
    }
  );
  const prevOrganizerEmail =
    swr.data?.event?.organizer?.email || swr.data?.event?.data?.organizerEmail;
  const currOrganizerEmail =
    event.organizer?.email || event.data?.organizerEmail;

  const { call: send, state } = useLiveAsyncCall(async () => {
    const resp = await apiService.event.sendIntakeForm(form.eventId, form.id);
    return resp.data.form;
  });

  const nameFilled = event.organizer
    ? !!event.organizer.firstName
    : !!event.data?.organizerName;

  const onSend = async () => {
    if (swr.isLoading || swr.error) return;
    const updatedForm = await send();
    if (!updatedForm) return;
    props.onConfirm(updatedForm);
  };

  const error = swr.error || state.error;

  return (
    <ModalWrapper containerClassName='w-90' borderStyle='gray'>
      <div className='px-5 py-5 flex flex-col gap-6'>
        <div>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            This will send an email to the user with a link to their intake
            form. Are you sure you want to send it?
          </ConfirmCancelModalText>
          {prevOrganizerEmail &&
            currOrganizerEmail &&
            prevOrganizerEmail !== currOrganizerEmail && (
              <div className='mt-2 text-center text-sms font-medium text-tertiary'>
                <div>
                  Previously POC: <b>{prevOrganizerEmail}</b>
                </div>
                <div>
                  Now POC: <b>{currOrganizerEmail}</b>
                </div>
              </div>
            )}
          {!nameFilled && (
            <div className='mt-2 text-center text-sms font-medium text-red-002'>
              <div>
                Please update the{' '}
                <Link
                  to={
                    event.organizer
                      ? `/admin/organizations/${
                          event.organizer.orgId
                        }/members?q=${encodeURIComponent(
                          event.organizer.email
                        )}`
                      : `/admin/toolkit/events/${event.id}/edit`
                  }
                  target='_blank'
                  className='underline italic'
                >
                  POC's name
                </Link>{' '}
                before continue.
              </div>
            </div>
          )}
        </div>
        <div className='flex items-center justify-center mt-auto gap-1'>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='w-37.5 h-10 btn-secondary'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='w-37.5 h-10 btn-primary flex items-center justify-center gap-2'
              disabled={state.state.isRunning || swr.isLoading || !nameFilled}
              onClick={onSend}
            >
              {state.state.isRunning && <Loading text='' />}
              <div>Send</div>
            </button>
          </div>
          {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        </div>
      </div>
    </ModalWrapper>
  );
}

function UpdateFormStatusModal(props: {
  event: Event;
  form: DtoEventIntakeForm;
  onCancel: () => void;
  onConfirm: (form: DtoEventIntakeForm) => void;
}) {
  const { form } = props;
  const [currStatus, setCurrStatus] = useState(form.status);
  const swr = useSWR(
    `/events/${form.eventId}/intake/${form.id}/prev-event`,
    async () => {
      const resp = await apiService.event.getPrevEvent(form.eventId, form.id);
      return resp.data;
    }
  );

  const { call: update, state } = useLiveAsyncCall(async () => {
    const resp = await apiService.event.updateIntakeFormStatus(
      form.eventId,
      form.id,
      currStatus
    );
    return resp.data.form;
  });

  const onUpdate = async () => {
    if (swr.isLoading || swr.error) return;
    if (currStatus !== form.status) {
      const updatedForm = await update();
      if (!updatedForm) return;
      props.onConfirm(updatedForm);
    } else {
      props.onCancel();
    }
  };

  const error = swr.error || state.error;

  const allStatuses = [
    EnumsIntakeFormStatus.IntakeFormStatusFilled,
    EnumsIntakeFormStatus.IntakeFormStatusUnfilled,
  ];

  return (
    <ModalWrapper containerClassName='w-90' borderStyle='gray'>
      <div className='px-5 py-5 flex flex-col gap-6'>
        <ConfirmCancelModalHeading>
          Update Intake Form Status
        </ConfirmCancelModalHeading>
        <div>
          {allStatuses.map((s) => (
            <label key={s} className='flex items-center my-1 cursor-pointer'>
              <input
                type='radio'
                name='role'
                className='field-radio'
                checked={currStatus === s}
                onChange={() => setCurrStatus(s)}
              />
              <p className='ml-8'>
                {s === 'filled' ? 'Ready' : 'Not Ready (TBD)'}
              </p>
            </label>
          ))}
        </div>
        <div className='flex items-center justify-center mt-auto gap-1'>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='w-37.5 h-10 btn-secondary'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='w-37.5 h-10 btn-primary flex items-center justify-center gap-2'
              disabled={state.state.isRunning || swr.isLoading}
              onClick={onUpdate}
            >
              {state.state.isRunning && <Loading text='' />}
              <div>Update</div>
            </button>
          </div>
          {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        </div>
      </div>
    </ModalWrapper>
  );
}

export function IntakeFormColumn(props: { event: Event }) {
  const { event } = props;
  const forceUpdate = useForceUpdate();
  const actions: Action<
    | 'create'
    | 'link'
    | 'copy'
    | 'send'
    | 'view'
    | 'delete'
    | 'summary'
    | 'update-status'
  >[] = [];
  const isPastEvent = Date.now() > new Date(event.startAt).getTime();
  const { send: sendNotification } = useNotificationDataSource();
  const user = useUser();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const toast = (message: string) => {
    sendNotification({
      id: uuidv4(),
      toUserClientId: user.id,
      type: NotificationType.General,
      createdAt: Date.now(),
      metadata: {
        message,
      },
    });
  };

  if (event.intakeForm) {
    actions.push(
      {
        kind: 'button',
        key: 'view',
        icon: <EyeIcon />,
        text: 'View Intake',
        className: 'text-white',
        onClick: () => {
          if (!event.intakeForm) return;
          window.open(
            `../events/${event.id}/intake/${event.intakeForm.id}`,
            '_blank'
          );
        },
      },
      {
        kind: 'button',
        key: 'copy',
        icon: <CopyIcon />,
        text: 'Copy Intake Link',
        className: 'text-white',
        onClick: () => {
          copy(`${window.origin}/events/${event.id}/intake`);
          toast('Intake link copied!');
        },
      },
      {
        kind: 'button',
        key: 'send',
        icon: <EmailIcon2 className='w-3.5 fill-current' />,
        text: event.intakeForm.lastSentAt
          ? 'Re-send Intake Email'
          : 'Send Intake Email',
        className: 'text-white',
        onClick: async () => {
          const form = event.intakeForm;
          if (!form) return;
          triggerModal({
            kind: 'custom',
            element: (p) => (
              <SendIntakeEmailModal
                event={event}
                form={form}
                onCancel={p.internalOnCancel}
                onConfirm={(form) => {
                  p.internalOnConfirm();
                  event.intakeForm = form;
                  forceUpdate();
                  toast('Intake form sent!');
                }}
              />
            ),
          });
        },
      },
      {
        kind: 'button',
        key: 'summary',
        icon: <InfoIcon className='w-3.5 fill-current' />,
        text: 'Send Summary',
        className: 'text-white',
        onClick: async () => {
          if (!event.intakeForm) return;
          await apiService.event.sendIntakeSummary(
            event.id,
            event.intakeForm.id
          );
          forceUpdate();
          toast('Intake summary sent!');
        },
      },
      {
        kind: 'button',
        key: 'update-status',
        icon: <EditIcon className='w-3.5 fill-current' />,
        text: 'Update Status',
        className: 'text-white',
        onClick: async () => {
          const form = event.intakeForm;
          if (!form) return;
          triggerModal({
            kind: 'custom',
            element: (p) => (
              <UpdateFormStatusModal
                event={event}
                form={form}
                onCancel={p.internalOnCancel}
                onConfirm={(form) => {
                  p.internalOnConfirm();
                  event.intakeForm = form;
                  forceUpdate();
                  toast('Intake form status updated!');
                }}
              />
            ),
          });
        },
      },
      {
        kind: 'button',
        key: 'delete',
        icon: <DeleteIcon />,
        text: 'Delete Intake',
        className: 'text-red-002',
        onClick: async () => {
          const form = event.intakeForm;
          if (!form) return;
          if (form.lastSentAt) {
            const resp = await triggerModal({
              kind: 'confirm-cancel',
              prompt: (
                <div className='px-5 py-2'>
                  <ConfirmCancelModalHeading>
                    Are you sure?
                  </ConfirmCancelModalHeading>
                  <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                    This form has been sent out, unlinking it will make it
                    unavailable to the POC.
                  </ConfirmCancelModalText>
                </div>
              ),
              confirmBtnLabel: 'Delete',
              confirmBtnVariant: 'delete',
              cancelBtnLabel: 'Cancel',
            });
            if (resp.result === 'canceled') return;
          }
          await apiService.event.deleteIntakeForm(event.id, form.id);
          event.intakeForm = undefined;
          forceUpdate();
          toast('Intake form deleted!');
        },
      }
    );
  } else {
    if (!isPastEvent) {
      actions.push(
        {
          kind: 'button',
          key: 'create',
          icon: <></>,
          text: 'Create New',
          className: 'text-white',
          onClick: async () => {
            const resp = await apiService.event.createIntakeForm(event.id);
            event.intakeForm = resp.data.form;
            forceUpdate();
            toast('Intake form created!');
          },
        },
        {
          kind: 'button',
          key: 'link',
          icon: <></>,
          text: 'Link Existing',
          className: 'text-white',
          onClick: () => {
            triggerModal({
              kind: 'custom',
              element: (p) => (
                <LinkIntakeModal
                  event={event}
                  onCancel={p.internalOnCancel}
                  onConfirm={(form) => {
                    p.internalOnConfirm();
                    event.intakeForm = form;
                    forceUpdate();
                    toast('Intake form linked!');
                  }}
                />
              ),
            });
          },
        }
      );
    }
  }

  if (event.type === 'ond') return null;
  return (
    <div className='flex items-center justify-end gap-2'>
      <div>
        {match(event)
          .when(
            () => !event.intakeForm,
            () => '-'
          )
          .when(
            () => event.intakeForm?.status === 'filled',
            () => 'Ready!'
          )
          .otherwise(() => 'TBD')}
      </div>
      {event.status === 'active' && actions.length > 0 && (
        <ActionSheet actions={actions} />
      )}
    </div>
  );
}

export function IntakeSummary() {
  const { eventId, formId } = useParams();
  const swr = useSWR(
    eventId && formId ? `/events/${eventId}/intake/${formId}/summary` : null,
    async () => {
      if (!eventId || !formId) return;
      const resp = await apiService.event.getIntakeSummary(eventId, formId);
      return resp.data;
    }
  );

  useTitle(makeTitle(swr.data?.subject ?? 'Intake Summary'));

  return (
    <div className='w-full h-full px-10 text-white'>
      {match(swr)
        .when(
          () => swr.isLoading,
          () => <Loading />
        )
        .when(
          () => swr.error,
          () => <div className='text-red-002 text-sms'>{err2s(swr.error)}</div>
        )
        .otherwise(() => {
          return (
            <div className='w-full flex flex-col gap-5'>
              <header className='text-2xl font-bold'>
                {swr.data?.subject}
              </header>
              <div className='w-full flex flex-col gap-5'>
                <div className='flex flex-col gap-1'>
                  <div className='text-base font-bold'>Status</div>
                  <div className='text-sm'>
                    {swr.data?.form.status === 'filled' ? 'Ready!' : 'TBD'}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-base font-bold'>Last Sent At</div>
                  <div className='text-sm'>
                    {swr.data?.form.lastSentAt
                      ? EventUtils.NYTime(swr.data?.form.lastSentAt)
                      : 'N/A'}
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='text-base font-bold'>Last Updated At</div>
                  <div className='text-sm'>
                    {swr.data?.form.updatedAt
                      ? EventUtils.NYTime(swr.data?.form.updatedAt)
                      : 'N/A'}
                  </div>
                </div>
                {swr.data?.items.map((item, i) => (
                  <div key={i} className='flex flex-col gap-1'>
                    <div className='text-base font-bold'>{item.label}</div>
                    <div className='text-sm'>{item.value || 'N/A'}</div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
}

function IntakeEventInfo(props: { event: DtoIntakeEvent }) {
  const { event } = props;
  const logoSrc = MediaUtils.PickMediaUrl(fromMediaDTO(event.orgLogo), {
    priority: [MediaFormatVersion.SM],
  });
  const startAt = utcToZonedTime(event.startAt, event.timezone);
  const endAt = utcToZonedTime(event.endAt, event.timezone);
  return (
    <div className='w-full bg-modal border border-secondary rounded-xl px-4 py-5 flex items-center gap-5'>
      {logoSrc ? (
        <div className='w-21 h-21 rounded-xl overflow-hidden'>
          <img
            src={logoSrc}
            alt='Organization Logo'
            className='w-full h-full object-cover'
          />
        </div>
      ) : (
        <div className='w-0 h-21'></div>
      )}
      <div className='flex flex-col gap-1'>
        <div className='font-bold'>{`${event.orgName} Event on Luna Park`}</div>
        <div className='text-sms w-60 truncate'>
          <div>{format(startAt, 'EEE, MM/dd/yy')}</div>
          <div>{`${format(startAt, 'h:mm a')} - ${format(endAt, 'h:mm a')} ${
            event.timezone
          }`}</div>
        </div>
      </div>
    </div>
  );
}

type FormData = ModelsEventIntakeFormData;

function FieldLabel(props: { label: string; required?: boolean }) {
  return (
    <label className='text-2xl font-bold'>
      {props.label}
      {props.required ? '*' : ''}
    </label>
  );
}

function TextField(props: {
  name: keyof FormData;
  label: string;
  desc?: string;
  placeholder: string;
  required?: boolean;
  onEnter?: () => void;
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required }}
      render={({ field, fieldState }) => (
        <div className='flex flex-col gap-2'>
          <FieldLabel {...props} />
          {props.desc && (
            <div className='text-xl text-secondary italic'>{props.desc}</div>
          )}
          <input
            {...field}
            value={field.value ?? ''}
            className={`h-13 text-3xl appearance-none outline-none focus:outline-none 
            focus:ring-0 bg-transparent border-b ${
              fieldState.error ? 'border-red-500' : 'border-secondary'
            } resize-none`}
            placeholder={props.placeholder}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.onEnter?.();
              }
            }}
            autoFocus
          />
        </div>
      )}
    />
  );
}

function MultipleChoiceOption(props: {
  label: string;
  defaultValue?: string;
  index: number;
  selected: boolean;
  onSelect: (value: string) => void;
  inputable?: boolean;
  placeholder?: string;
}) {
  const { label, defaultValue, index } = props;
  const [value, setValue] = useState(defaultValue);
  const [typing, setTyping] = useState(false);
  const ref = useRef(null);

  const onClick = () => {
    if (props.inputable) {
      setTyping(true);
    } else {
      props.onSelect(defaultValue ?? '');
    }
  };

  const onFinishTyping = () => {
    setTyping(false);
    props.onSelect(value ?? '');
  };

  useOutsideClick(ref, () => {
    if (props.inputable && typing) {
      onFinishTyping();
    }
  });

  return (
    <div
      ref={ref}
      className={`bg-modal hover:bg-light-gray border ${
        props.selected ? 'border-white' : 'border-secondary'
      } rounded-lg w-full min-h-12 flex items-center cursor-pointer px-2 gap-2`}
      onClick={onClick}
    >
      {typing ? (
        <>
          <input
            className='appearance-none outline-none focus:outline-none focus:ring-0 bg-transparent h-full'
            type='text'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onFinishTyping()}
            autoFocus
          />
          <div
            className='ml-auto w-7 h-7 flex-shrink-0 bg-white text-black flex items-center justify-center rounded'
            onClick={(e) => {
              e.stopPropagation();
              onFinishTyping();
            }}
          >
            <FilledCheckIcon />
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-6 h-6 border flex-shrink-0 ${
              props.selected
                ? 'bg-white text-black'
                : 'border-white bg-transparent'
            } flex items-center justify-center text-sms font-bold`}
          >
            {String.fromCharCode(65 + index)}
          </div>
          <div className='text-sm sm:text-base'>
            {props.inputable ? value || label : label}
          </div>
          {props.selected && (
            <div className='ml-auto'>
              <FilledCheckIcon />
            </div>
          )}
        </>
      )}
    </div>
  );
}

const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

function MultipleChoiceField(props: {
  name: keyof FormData;
  label: string;
  desc?: string;
  required?: boolean;
  options: {
    label: string;
    value: string;
  }[];
  inputable?: {
    label: string;
    placeholder?: string;
  };
  onSelected?: (val: string) => void;
}) {
  const { control, setValue, getValues } = useFormContext();
  const resetInvalidValue = useLiveCallback(() => {
    const value = getValues(props.name);
    if (value) {
      const found = props.options.find((o) => o.value === value);
      if (!found) {
        setValue(props.name, undefined);
      }
    }
  });
  useLayoutEffect(() => resetInvalidValue(), [resetInvalidValue]);
  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ required: props.required }}
      render={({ field, fieldState }) => {
        return (
          <div className='flex flex-col gap-2'>
            <FieldLabel {...props} />
            {props.desc && (
              <div className='text-xl text-secondary italic'>{props.desc}</div>
            )}
            <div className='text-xl flex flex-col gap-2'>
              {props.options.map((o, i) => (
                <MultipleChoiceOption
                  key={i}
                  label={o.label}
                  defaultValue={o.value}
                  index={i}
                  selected={field.value === o.value}
                  onSelect={(val) => {
                    field.onChange(val);
                    props.onSelected?.(val);
                  }}
                />
              ))}
              {props.inputable && (
                <MultipleChoiceOption
                  label={props.inputable.label}
                  index={props.options.length}
                  selected={
                    field.value &&
                    !props.options.find((o) => o.value === field.value)
                  }
                  onSelect={(val) => {
                    field.onChange(val);
                    props.onSelected?.(val);
                  }}
                  inputable
                  placeholder={props.inputable.placeholder}
                />
              )}
            </div>
            {fieldState.error && (
              <div className='text-red-500 text-sms'>
                This field is required
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

function GoNextButton(props: { onClick: () => void }) {
  return (
    <button
      type='button'
      className='btn-primary w-20 h-10 text-xl font-bold rounded-lg flex items-center justify-center gap-1'
      onClick={() => props.onClick()}
    >
      <div>OK</div>
      <FilledCheckIcon />
    </button>
  );
}

function FormStep(props: {
  step: number;
  currentStep: number;
  children?: ReactNode;
}) {
  if (props.step !== props.currentStep) return null;
  return (
    <EnterExitTailwindTransition
      initialClasses={'translate-y-full opacity-0'}
      enteredClasses={`translate-y-0 opacity-100`}
    >
      {(ref, initial) => (
        <div
          ref={ref}
          className={`w-full flex flex-col gap-8 
                transition-all transform-gpu duration-500 ${initial}`}
        >
          {props.children}
        </div>
      )}
    </EnterExitTailwindTransition>
  );
}

function trueValue(value: string | null | undefined) {
  const v = value?.toLowerCase();
  return v === 'yes' || v === 'true' || v === '1';
}

function ProgressBar(props: { totalSteps: number; currentStep: number }) {
  const percentage =
    props.totalSteps > 0 ? props.currentStep / props.totalSteps : 0;
  return (
    <div className='w-full absolute top-0 h-1'>
      <div className='w-full h-full bg-primary bg-opacity-60 absolute top-0 z-0'></div>
      <div
        className='h-full bg-primary absolute top-0 z-1 transition-size duration-150'
        style={{
          width: `${percentage * 100}%`,
        }}
      ></div>
    </div>
  );
}

export function IntakeFormEditor(props: { event: DtoIntakeEvent }) {
  const { event } = props;
  const form = useForm<FormData>({
    defaultValues: event.intakeForm.data ?? undefined,
  });
  const [currStep, setCurrStep] = useState(0);
  const [prevStep, setPrevStep] = useState(-1);

  const { isSubmitting, isValid, errors } = form.formState;

  const goNext = async (by = 1) => {
    const valid = await form.trigger();
    if (!valid) return;
    setPrevStep(currStep);
    setCurrStep((prev) => prev + by);
  };

  const goPrev = () => {
    if (prevStep <= 0) return;
    setCurrStep(prevStep);
    setPrevStep(prevStep - 1);
  };

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      await apiService.event.updateIntakeForm(event.id, event.intakeForm.id, {
        data,
      });
    } catch (error) {
      form.setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
    goNext();
  });

  const hasFirewall = form.watch('hasFirewall');

  const editable = new Date(event.startAt).getTime() - Date.now() >= 0;

  const steps = [
    <Fragment>
      <IntakeEventInfo event={event} />
      <div className='w-full flex flex-col gap-4.5 mt-2'>
        <div className='text-2.5xl sm:text-3.5xl font-bold'>
          Let’s personalize your event
        </div>
        <div>
          Providing us more information helps us to ensure your experience is
          tailored to your team. All these are optional and you can also edit
          them up to 24 hours before your event.
        </div>
      </div>
      <div className='w-full flex flex-col items-center justify-center gap-1'>
        <button
          type='button'
          className='btn-primary w-60 h-12 text-2xl font-bold'
          onClick={() => goNext()}
          disabled={!editable}
        >
          Start
        </button>
        {!editable && (
          <div className='text-sms text-tertiary'>This event has started.</div>
        )}
      </div>
    </Fragment>,
    <Fragment>
      <TextField
        name='tel'
        label='What’s your phone number?'
        desc={`We can resolve most issues by email so we'll only call if it's a time-sensitive issue related to your event.`}
        placeholder='(201) 555-0123'
        onEnter={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <TextField
        name='website'
        label='What’s your company website?'
        placeholder='www.yourcompany.com'
        onEnter={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <TextField
        name='highlight'
        label='Is there anyone or anything else you want to highlight? Please list any names.'
        desc={`Examples: shout outs for new hires, birthdays, a leader with an announcement.`}
        placeholder='Type your answer here...'
        onEnter={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <MultipleChoiceField
        name='languagePreference'
        label='Is this event for a US-based team or an international team?'
        desc={`Don't worry, we host global teams all the time and will adjust the experience accordingly!`}
        options={[
          {
            label: 'US-based team',
            value: 'US-based team',
          },
          {
            label: 'International team',
            value: 'International team',
          },
        ]}
        required
        onSelected={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <MultipleChoiceField
        name='holidayEvent'
        label='Would you like the event to be Holiday themed?'
        desc={
          'Our Holiday themed event is packed with holiday-themed fun that brings out the festive spirit in every team.'
        }
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        required
        onSelected={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <MultipleChoiceField
        name='prize'
        label='Are you planning to offer a prize? If so, please share the details.'
        desc={`Prizes are not required but add a bit of fun!`}
        options={[
          {
            label: 'Nope, Just Bragging Rights',
            value: 'Nope, Just Bragging Rights',
          },
          {
            label: 'Yes, $25 AMZN Gift Card (per player on the winning team)',
            value: 'Yes, $25 AMZN Gift Card (per player on the winning team)',
          },
        ]}
        inputable={{
          label: 'Other',
          placeholder: 'Type your answer',
        }}
        required
        onSelected={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <TextField
        name='department'
        label='What group / department will be attending?'
        desc={`This could be the entire company, a specific team, an ERG, or something else.`}
        placeholder='Type your answer here...'
        required
        onEnter={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <TextField
        name='participantsCount'
        label='What is the maximum number of people that could attend your event?'
        placeholder='Type your answer here...'
        required
        onEnter={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <MultipleChoiceField
        name='attendedBefore'
        label='Has anyone from your group attended a Luna Park event before?'
        desc={`If the answer is yes, we'll be sure the content is fresh from the previous experience.`}
        options={YES_NO_OPTIONS}
        onSelected={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <MultipleChoiceField
        name='hasFirewall'
        label='One more thing, do you use a VPN or have any known firewall security protocols?'
        desc={`VPN or firewall can create critical issues during the game. If we know about it in advance, we can mitigate the issues.`}
        options={YES_NO_OPTIONS}
        required
        onSelected={(val) => goNext(trueValue(val) ? 1 : 2)}
      />
      <GoNextButton
        onClick={() => {
          goNext(trueValue(hasFirewall) ? 1 : 2);
        }}
      />
    </Fragment>,
    <Fragment>
      <MultipleChoiceField
        name='canDisableFirewall'
        label='Is the VPN or firewall something your team can easily turn off?'
        desc={`If you are unsure, please answer No.`}
        options={YES_NO_OPTIONS}
        required
        onSelected={() => goNext()}
      />
      <GoNextButton onClick={goNext} />
    </Fragment>,
    <Fragment>
      <TextField
        name='additionalNotes'
        label='Anything else you want your host to know?'
        desc={`We’ll do our best to accommodate!`}
        placeholder='Type your answer here...'
        onEnter={() => onSubmit()}
      />
      <div className='w-full flex flex-col items-center justify-center gap-1'>
        <button
          type='button'
          className='btn-primary w-60 h-10 text-xl font-bold rounded-lg flex items-center justify-center gap-1'
          onClick={() => onSubmit()}
          disabled={isSubmitting || !isValid || !editable}
        >
          {isSubmitting && <Loading text='' />}
          Submit
        </button>
        {errors.root?.serverError && (
          <div className='text-sms text-red-002'>
            {errors.root.serverError.message}
          </div>
        )}
      </div>
    </Fragment>,
    <Fragment>
      <div className='w-full flex items-center justify-center text-center text-3xl'>
        Thank you for your feedback. We will curate a set of games just for your
        team.{' '}
        {trueValue(hasFirewall) &&
          `We will also reach out shortly to schedule a
                brief VPN / firewall test.`}
      </div>
    </Fragment>,
  ];

  return (
    <div
      className='w-full h-full flex-1 overflow-y-auto scrollbar 
    bg-game-library bg-w-full bg-no-repeat bg-top bg-local flex flex-col
    px-4 sm:px-0'
    >
      <ProgressBar totalSteps={steps.length} currentStep={currStep} />
      <header className='w-full flex justify-center absolute top-0'>
        <div className='w-full sm:w-120 md:w-160 flex justify-center'>
          <img src={lpLogo} alt='Luna Park' className='w-2/3 md:w-1/2' />
        </div>
      </header>
      <FormProvider {...form}>
        <main className='w-full h-full flex items-center justify-center text-white'>
          <div className='w-full sm:w-120 md:w-160'>
            {steps.map((s, i) => (
              <FormStep key={i} step={i} currentStep={currStep}>
                {s}
              </FormStep>
            ))}
          </div>
        </main>
      </FormProvider>
      {currStep > 0 && currStep < steps.length - 1 && (
        <div className='w-max flex items-center justify-center absolute bottom-2 left-2 sm:left-auto sm:right-2'>
          <button
            type='button'
            className='btn-primary w-8 h-8 rounded-md rounded-r-none flex items-center justify-center'
            disabled={prevStep <= 0}
            onClick={() => goPrev()}
          >
            <ArrowUpIcon />
          </button>
          <button
            type='button'
            className='btn-primary w-8 h-8 rounded-md rounded-l-none flex items-center justify-center'
            disabled={currStep >= steps.length - 2}
            onClick={() => goNext()}
          >
            <ArrowDownIcon />
          </button>
        </div>
      )}
    </div>
  );
}
